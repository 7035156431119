import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    all,
    assocPath,
    concat,
    includes,
    find,
    hasPath,
    path,
    propOr,
    slice,
    without
} from 'ramda';
import { History } from '@plotly/dash-component-plugins';
import Link from 'dash-core-components/lib/components/Link.react.js';
import algoliasearch from 'algoliasearch/lite';


/*
 * event polyfill for IE
 * https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent
 */
function CustomEvent(event, params) {
    // eslint-disable-next-line no-param-reassign
    params = params || {
        bubbles: false,
        cancelable: false,
        // eslint-disable-next-line no-undefined
        detail: undefined,
    };
    const evt = document.createEvent('CustomEvent');
    evt.initCustomEvent(
        event,
        params.bubbles,
        params.cancelable,
        params.detail
    );
    return evt;
}
CustomEvent.prototype = window.Event.prototype;

function traverse(obj, pathArray, func) {
    if (obj.chapters) {
        for(let i=0; i < obj.chapters.length; i++) {
            traverse(obj.chapters[i], pathArray.concat(['chapters', i]), func);
        }
    }
    func(obj, pathArray);
}

function searchChapter(chapter) {
    return (searchWord) => {
        if(chapter.hide_in_sidebar) {
            return false;
        }
        const terms = [
            'name', 'description', 'description_short',
            'url', 'meta_keywords'
        ];
        for(let i=0; i<terms.length; i++) {
            if(includes(searchWord.toLowerCase(), propOr('', terms[i], chapter).toLowerCase())) {
                return true;
            }
        }
        return false;
    }
}

function filterUrls (urls, search) {
    const matches = [];
    traverse({'chapters': urls}, [], function(o, pathArray) {
        const searchWords = without('', search.split(' '));

        if (all(searchChapter(o), searchWords)) {
            matches.push(pathArray);
        }

    })

    if(matches.length > 0) {
        let newObj = {};
        const searchResults = [];
        matches.forEach(matchPath => {
            const searchResult = {
                'names': []
            };
            for(let i=0; i<matchPath.length + 1; i++) {
                const namePath = concat(slice(0, i, matchPath), ['name']);
                if(hasPath(namePath, {'chapters': urls})) {
                    searchResult.names.push(path(namePath, {'chapters': urls}));
                }
            }
            searchResult.url = path(concat(matchPath, ['url']), {'chapters': urls});
            searchResult.description = path(concat(matchPath, ['description']), {'chapters': urls});
            searchResult.description_short = path(concat(matchPath, ['description_short']), {'chapters': urls});
            newObj = assocPath(matchPath, path(matchPath, {'chapters': urls}), newObj)
            searchResults.push(searchResult);
        });
        return {
            chapters: newObj.chapters,
            searchResults
        };
    }
    return {}
}

function getPathParts(prefix, chapter) {
    const pathname = (chapter && chapter.url) || window.location.pathname;

    const localURLs = new RegExp(`^${prefix}(/r|/julia|/fsharp|/matlab)?(/.*[^/])?/?$`);
    const parts = (pathname || '/').match(localURLs);
    if (!parts) {
        return {external: true, content: pathname};
    }
    return {lang: parts[1] || '', content: parts[2] || ''};
}

/**
 * Left sidebar, with TOC of the whole docs app
 */
const Sidebar = ({ id, children, urls, prefix, apiKey, searchIndex, pagesList, updateComponent, setProps }) => {
    const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [, forceUpdate] = useState();

    const onLocationChanged = () => {
        forceUpdate((prev) => !prev);
    };

    const appID = '7EK9KHJW8M';

    useEffect(() => {
        const _clearOnLocationChanged = History.onChange(onLocationChanged);
        return () => {
            _clearOnLocationChanged();
        };
    }, []);

    useEffect(() => {
        const fetchData = async (searchTerm, inputUrls) => {
            if (!apiKey || !searchIndex) {
                const offlineSearchResults = filterUrls(
                    JSON.parse(JSON.stringify(inputUrls)),
                    searchTerm
                ).searchResults;
                setSearchResults(offlineSearchResults);
            } else {
                const client = algoliasearch(appID, apiKey);
                const index = client.initIndex(searchIndex);
                index.search(search).then(({hits}) => {
                    const filteredHits = hits.filter((hit) =>
                        pagesList.hasOwnProperty(hit.url)
                    );
                    const processedResults = filteredHits.map((obj) => {
                        const page = pagesList[obj.url];
                        return {
                            names: [page.previous_level, page.name],
                            url: obj.url,
                            description: page.description,
                            name: page.name,
                        };
                    });
                    setSearchResults(processedResults);
                });
            }
        };

        if (search.length > 2) {
            fetchData(search, urls);
        } else {
            setSearchResults([]);
        }
    }, [search]);


        function handleKeyUp(event) {
            if (event.keyCode === 13) {
                if(searchResults.length > 0) {
                    window.history.pushState({}, '', searchResults[0].url);
                    window.dispatchEvent(new CustomEvent('_dashprivate_pushstate'));
                }
            }
        }

        return (
            <div className="sidebar">
                <input
                    autoFocus
                    tabIndex="1"
                    type="search"
                    autoComplete="false"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    onKeyUp={handleKeyUp}
                    id="sidebar-search-input"
                    placeholder={'Filter...'}
                />
                {
                    search.length > 2 ?
                    <SearchResults
                        search={search}
                        searchResults={searchResults}
                        children={children}
                        prefix={prefix}
                    />
                    :
                    <TreeSidebar
                        urls={urls}
                        depth={0}
                        force_closed={
                            !getPathParts(prefix).content && !search
                        }
                        prefix={prefix}
                    />
                }
            </div>
        );
}

Sidebar.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * Custom content in the "no results found" panel
     */
    children: PropTypes.node,

    /**
     * URLs
     */
    urls: PropTypes.array,

    /**
     * path prefix, eg "/docs"
     */
    prefix: PropTypes.string,

    /**
     * API key
     */
    apiKey: PropTypes.string,

    /**
     * Search Index
     */
    searchIndex: PropTypes.string,
    
    /**
     * pagesList
     */
    pagesList: PropTypes.object,

    /**
     * Dummy prop to trigger component update
     */
    updateComponent: PropTypes.bool,

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func
};

const SearchResults = ({children, searchResults, search, prefix}) => {
        if (!searchResults || searchResults.length === 0) {
            return (
                <div className='no-results'>
                    <span>{'No results found.'}</span>

                    {children}

                    <span>{'Try the same search on '}</span>
                    <a href={`https://google.com/search?q=site%3Adash.plotly.com+${search}`}>
                        Google
                    </a>
                    <span>{', '}</span>
                    <a href={`https://duckduckgo.com?q=site%3Adash.plotly.com+${search}`}>
                        Duck Duck Go
                    </a>
                    <span>{', or the '}</span>
                    <a href={`https://community.plotly.com/search?q=${search}%20category%3A16`}>
                        Dash Community Forum
                    </a>
                    <span>{'. '}</span>

                    <hr/>

                    <small>
                        {`No luck? Here is a more specific search by wrapping your
                        query in "quotes" on `}
                        <a href={`https://google.com/search?q=site%3Adash.plotly.com+"${search}"`}>
                            Google
                        </a>
                        <span>{', '}</span>
                        <a href={`https://duckduckgo.com?q=site%3Adash.plotly.com+"${search}"`}>
                            Duck Duck Go
                        </a>
                        <span>{', or the '}</span>
                        <a href={`https://community.plotly.com/search?q="${search}"%20category%3A16`}>
                            Dash Community Forum
                        </a>
                        <span>{'. '}</span>
                    </small>

                    <hr/>

                    {
                        children ? (
                            <small>
                                <i>
                                    {`Still no luck? Get help for what you are
                                      looking for by opening a topic on the `}
                                    <a href="https://community.plotly.com/c/dash">Dash Community Forum</a>
                                    {'.'}
                                </i>
                            </small>
                        ) : null
                    }

                </div>
            )
        }

        const resultItems = [];
        for(let i=0; i<searchResults.length; i++) {
            // Only display the last two names
            searchResults[i].name = searchResults[i].names.slice(
                searchResults[i].names.length - 2,
                searchResults[i].names.length
            ).join(' > ');
            resultItems.push(link(searchResults[i], prefix));
        }

        return (
            <div className='search-results'>
                {resultItems}
            </div>
        );
}

SearchResults.propTypes = {
    children: PropTypes.any,
    searchResults: PropTypes.array,
    search: PropTypes.string,
    prefix: PropTypes.string,
};

function link(chapter, prefix) {
    if(!chapter.url) {
        return null;
    }
    let title = null;
    if (chapter.description_short) {
        title = chapter.description_short.trim();
    } else if (chapter.description) {
        title = chapter.description.trim();
    }
    const {lang, content} = getPathParts(prefix);
    const {content: chapterContent, external} = getPathParts(prefix, chapter);

    const linkProps = {
        href: external ? chapterContent : (prefix + lang + chapterContent),
        title: title,
        className: `${content === chapterContent ? 'active': ''}`,
        children: chapter.name
    };
    if (chapter.url.indexOf('http') === 0) {
        return <a {...linkProps}/>;
    }
    return <Link {...linkProps}/>;
}

const TreeSidebar = ({force_closed, depth, urls, prefix}) => {
        const chapter_elements = [];

        for(let i=0; i<urls.length; i++) {
            const chapter = urls[i];
            if(!chapter) {
                continue;
            } else if (chapter.chapters && !chapter.hide_chapters_in_sidebar) {
                const currentPage = getPathParts(prefix).content;

                const isCurrentPage = url => (
                    url && getPathParts(prefix, {url}).content === currentPage
                );
                const hasCurrentPage = chapters => Boolean(find(
                    ({url, chapters}) => (
                        isCurrentPage(url) || (chapters && hasCurrentPage(chapters))
                    ),
                    chapters || []
                ));

                const open = !force_closed && hasCurrentPage(chapter.chapters);
                chapter_elements.push(
                    <details open={open} >
                        <summary >{chapter.name}</summary>
                        <TreeSidebar
                            urls={chapter.chapters}
                            depth={depth + 1}
                            force_closed={force_closed}
                            prefix={prefix}
                        />
                    </details>
                );
            } else {
                chapter_elements.push(link(chapter, prefix));
            }
        }

        return (
            <div className={`sidebar--${depth}`}>
                {chapter_elements}
            </div>
        )
}

TreeSidebar.propTypes = {
    force_closed: PropTypes.bool,
    depth: PropTypes.number,
    urls: PropTypes.array,
    prefix: PropTypes.string
};

export default Sidebar;

