import React, { Component } from 'react';
import PropTypes from 'prop-types';

window.pageMenuScroll = function pageMenuScroll(id, menuLinks_id, total_elements, stripped_id) {
    var el = document.getElementById(id);
    if (el && el.scrollIntoView) {
        el.scrollIntoView();
        for (let i = 0; i < total_elements; i++) {
            const eli = document.getElementById(stripped_id + String(i));
            if (eli) {
                eli.classList.remove("toc-menu-link-active");
            }
        }
        document.getElementById(menuLinks_id).classList.add("toc-menu-link-active")
        window.location.replace(`${window.location.href.split('#')[0]}#${id}`)
    }
}

function isInViewport(el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
}

function changeColor() {
    const menuLinks = window.menuLinks;
    if (!menuLinks) {
        return;
    }

    function getTextEl(i) {
        return document.getElementById(menuLinks[i].text_id);
    }

    function getLinkEl(i) {
        return document.getElementById(menuLinks[i].id);
    }

    for (var i = 0; i < menuLinks.length; i++) {
        var texti = getTextEl(i);
        if (texti && isInViewport(texti)) {
            for (var j = 0; j < menuLinks.length; j++) {
                const linkj = getLinkEl(j);
                if (linkj) {
                    linkj.classList.remove("toc-menu-link-active");
                }
            }
            getLinkEl(i).classList.add("toc-menu-link-active");
            break;
        }
    }
}
document.addEventListener('scroll', changeColor);

function menu_level_style_dynamic(el, hasSpacing) {
    let indent = 0;
    const tagLower = String(el.tagName).toLowerCase();
    const {h1, h2, h3, h4} = hasSpacing;

    if (tagLower === 'h1') {
        indent = 0;
    }
    else if (tagLower === 'h2') {
        indent = h1;
    }
    else if (tagLower === 'h3') {
        indent = h1 + h2;
    }
    else if (tagLower === 'h4') {
        indent = h1 + h2 + h3;
    }
    else if (tagLower === 'h5') {
        indent = h1 + h2 + h3 + h4;
    }

    return `margin-left: ${10 * indent}px;`;
}

function hasPendingCallbacks() {
    var callbacksState =  Object.assign({}, window.store.getState().callbacks);
    delete callbacksState.stored;
    delete callbacksState.completed;

    return Array.prototype.concat.apply([], Object.values(callbacksState)).length > 0;
}

function hasLoadedContent() {
    return document.querySelector('#chapter:not([data-dash-is-loading])');
}

var CHECKED_INITIAL_URL = '';

/**
 * The right sidebar, showing and scrolling to contents of this page
 */
class PageMenu extends Component {
    constructor(props) {
        super(props);
        this.renderLinksInDom = this.renderLinksInDom.bind(this);
    }
    componentDidUpdate() {
        this.renderLinksInDom();
    }
    componentDidMount() {
        this.renderLinksInDom();
    }
    renderLinksInDom() {
        const renderInterval = setInterval(() => {
            const parent = document.getElementById('page-menu--links');
            const elements = document.querySelectorAll('h1, h2, h3, h4, h5, h6');
            const ignoreElements = document.querySelectorAll(`
                .example-container h1,
                .example-container h2,
                .example-container h3,
                .example-container h4,
                .example-container h5,
                .example-container h6
            `);

            const elementsArray = Array.from(elements);
            const ignoreElementsArray = Array.from(ignoreElements);
            const equals = (elementsArray.length === ignoreElementsArray.length) && elementsArray.every(function (element, index) {
                return element === ignoreElementsArray[index];
            });

            if ((elements.length === 0) || equals || !hasLoadedContent()) {
                /*
                 * Sometimes the page content isn't rendered on page load
                 * even though this component is updated by a callback that
                 * listens to the output of the content
                 * Also, sometimes the examples content is rendered before the content
                 * so we should verify if the elements array is not composed only by elements
                 * that should be ignored.
                 */
                return;
            }
            window.clearInterval(renderInterval);

            const hasSpacing = {
                h1: 0,
                h2: 0,
                h3: 0,
                h4: 0,
                h5: 0
            };

            const takenElements = [];

            for (let i = 0; i < elements.length; i++) {
                const el = elements[i];

                if (i === 0 || ignoreElementsArray.indexOf(el) > -1) {
                    continue;
                }

                if (!el.id) {
                    el.id = el.innerText
                        .toLowerCase()
                        .replace(/ /g, '-')
                        // Remove ' since we use quotes below in `onClick=pageMenuScroll('`
                        // Remove " just to be safe
                        .replace(/["']/g, '');
                }

                const tagLower = String(el.tagName).toLowerCase();
                if (tagLower in hasSpacing) {
                    hasSpacing[tagLower] = 1;
                }
                takenElements.push(el);
            }
            const menuLinks = [];
            const links = [];
            if (takenElements.length > 1) {
                links.push("<div class='page-menu--header'>On This Page</div>")
            }
            else {
                // 0 or 1 element - don't show anything.
                takenElements.splice(0, 1);
            }

            for (let i = 0; i < takenElements.length; i++) {
                const el = takenElements[i];

                /*
                 * TODO - Replace with a proper a and remove pageMenuScroll
                 * once https://github.com/plotly/dash-core-components/issues/769
                 * is fixed
                 */

                const toc_menu_level = menu_level_style_dynamic(el, hasSpacing);

                const text_id = el.innerText
                    .toLowerCase()
                    .replace(/["']/g, "")
                    .replace(/ /g, "-");

                menuLinks.push({
                    id: `page-menu--link-${i}`,
                    text_id,
                    text: el.innerText
                });
                const hash = el.innerText
                    .toLowerCase()
                    .replace(/[\s\uFEFF\xA0]/g, '-');

                links.push(`
                 <div class="page-menu--link-parent" style="${toc_menu_level}">

                    <a href="#${hash}" class="page-menu--link-decoration">
                        <span
                            id="page-menu--link-${i}"
                            class="page-menu--link "
                            onClick="pageMenuScroll('${el.id}', 'page-menu--link-${i}', ${takenElements.length} , 'page-menu--link-')"
                        >
                            ${el.innerText.replace(/</g, '&lt;').replace(/>/g, '&gt;')}
                        </span>
                    </a>
                </div>
                `);
            };

            parent.innerHTML = links.join('');
            window.menuLinks = menuLinks;

            // Check ONCE per page url for a hash in the URL to scroll to
            const anchor = window.location.hash.substr(1);
            if (anchor && (window.location.href !== CHECKED_INITIAL_URL)) {
                const waitForCallbacks = setInterval(() => {
                    if (!hasPendingCallbacks()) {
                        clearInterval(waitForCallbacks);
                        const el = document.getElementById(anchor);

                        if (el) {
                            setTimeout(() => {
                                el.scrollIntoView();
                            }, 1000);
                        }
                    }
                }, 100);
            }
            CHECKED_INITIAL_URL = window.location.href;
        }, 100);
    }

    render() {
        const { id, loading_state, style } = this.props;
        let pathnameClassName = window.location.pathname.replace(/\//g, '');
        if (pathnameClassName === '') {
            pathnameClassName = 'home';
        }

        return (
            <div
                data-dash-is-loading={
                    (loading_state && loading_state.is_loading) || undefined
                }
                id={id}
                className={`page-menu ${pathnameClassName}`}
                style={style}
            >
                <div id="page-menu--links" />
            </div>
        )
    }
}

PageMenu.defaultProps = {
    style: {}
}

PageMenu.propTypes = {
    /**
     * The ID used to identify this component in Dash callbacks.
     */
    id: PropTypes.string,

    /**
     * dummy prop to force updates
     */
    dummy: PropTypes.string,
    /**
     * dummy prop to force updates
     */
    dummy2: PropTypes.string,

    /**
     * Wrapper style prop
     */
    style: PropTypes.object,

    /**
     * Object that holds the loading state object coming from dash-renderer
     */
    loading_state: PropTypes.shape({
        /**
         * Determines if the component is loading or not
         */
        is_loading: PropTypes.bool,
        /**
         * Holds which property is loading
         */
        prop_name: PropTypes.string,
        /**
         * Holds the name of the component that is loading
         */
        component_name: PropTypes.string,
    }),

    /**
     * Dash-assigned callback that should be called to report property changes
     * to Dash, to make them available for callbacks.
     */
    setProps: PropTypes.func
};

export default PageMenu;
